import { Link } from "@nike/eds";
import "@nike/eds/dist/index.css";

function Footer() {
  const handleSlackNavigate = () => {
    window.location.href =
      "https://nike.enterprise.slack.com/archives/C06J38YK40M";
  };

  return (
    <div
      className="eds-flex eds-flex--justify-content-center eds-gap--32"
      id="footer"
    >
      {/* <Link variant="secondary">Feedback</Link> |
      <Link variant="secondary">Survey</Link> |*/}
      <Link variant="secondary" onClick={handleSlackNavigate}>
        CCOE-Slack
      </Link>
    </div>
  );
}

export default Footer;
