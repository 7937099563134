
import React from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import { useAuth } from "@nike/aegis-auth-react";
import client from "./auth/client.js";
import "./App.css";
import "@nike/eds/dist/index.css";
import Footer from "./Components/Footer";
import EmbedHome from "./Components/HomeDash.js";
import { Spinner } from "@nike/eds";



function App() {
  const { loginComplete } = useAuth(client)
  if (!loginComplete) return <Spinner size="large"/>
  const NotFound = () => {
    return (
      <>
        <h1>404 - Page Not Found</h1>
      </>
    );
  };
  return (
    <Router >
    <div className="app">
        <div className="content">
          <Routes>
            <Route path="/" element={<EmbedHome />} />
            <Route path="/compute" element={ <EmbedHome />   } />
            <Route path="/compute/rav" element={<EmbedHome /> } />
            <Route path="/storage" element={<EmbedHome /> } />
            <Route path="*" element={<NotFound />}/>
          </Routes>
          <Footer />
        </div>
      </div>
    </Router>
  );
}

export default App;

//Recreate Login CloudRed ref 
