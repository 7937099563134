//import merge from "deepmerge";

const base = {
  oAuth: {
    client_id: "nike.techops.ops-dash",
    redirect_uri: window.location.origin,
  }
};

/*
const dev = merge(base, {
  oAuth: {
    redirect_uri:
        window.location.href.indexOf("cloudops-test.nike.com") !== -1
            ? window.location.origin
            : window.location.origin,
  }
});

const prod = merge(base, {
  oAuth: {
    redirect_uri:
        window.location.href.indexOf("cloudops.nike.com") !== -1
            ? window.location.origin
            : window.location.origin,
  }
});
*/

const config =
    base

export default config;
